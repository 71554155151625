<template>
	<div class='pt20 flex pb20'>
		<div class="back_box flex row-center col-center text-center" @click="$router.push(`/patentReport/${CategoryId}`)">返回<br>列表</div>
		<div class="bg-f5 w287">
			<pdf :src='img' v-for="i in pageNum" :key="i" :page="i" />
		</div>
	</div>
</template>

<script>
	import { getPatentsDetail } from '@/api'
	import pdf from "vue-pdf";
	export default {
		data() {
			return {
				CategoryId:null,
				id: null,
				img: '',
				pageNum: ''
			};
		},

		components: { pdf },

		created() {
			this.CategoryId=this.$route.params.CategoryId
			this.id = this.$route.params.id
			this.getPatentsDetail()
		},

		mounted() {},

		methods: {
			async getPatentsDetail() {
				const res = await getPatentsDetail({ id: this.id })
				this.img = res.data.patentPdf
				this.pageNum = res.data.pdfPage
				this.getPageNum(); //pdf分页处理
			},
			async getPageNum() {
				let loadingTask = pdf.createLoadingTask(this.img, { withCredentials: false })
				await loadingTask.promise.then(pdf => {
					this.pageNum = pdf.numPages
				}).catch(err => {
					console.error('pdf加载失败', err);
				})
			},
		}
	}
</script>
<style lang='scss' scoped>
	.back_box {
		position: sticky;
		top: 80px;
		width: 44px;
		height: 44px;
		background: #F7B500;
		border: 1px solid #F7B500;
		font-size: 11px;
		font-weight: 500;
		color: #595959;
	}
</style>
